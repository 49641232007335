export default {
  baseStyle: {
    // container: baseStyleContainer(props),
    track: {
      borderRadius: '4px',
      bg: 'neutral.100',
    },
    thumb: {
      boxShadow: '0 2px 6px 0 rgba(11, 37, 75, 0.12)',
      bg: 'azure.100',
      border: '8px solid',
      borderColor: 'white.90',
      backgroundClip: 'padding-box',
      _hover: {
        boxShadow: '0 6px 12px 0 rgba(11, 37, 75, 0.1), 0 2px 6px 0 rgba(11, 37, 75, 0.12)',
      },
      _focus: {
        boxShadow: '0 6px 12px 0 rgba(11, 37, 75, 0.1), 0 2px 6px 0 rgba(11, 37, 75, 0.12)',
      },
    },
    filledTrack: {
      bg: 'azure.100',
    },
  },
  sizes: {
    lg: {
      track: {
        height: '8px',
      },
      thumb: {
        width: '24px',
        height: '24px',
      },
    },
  },
};
