export default {
  baseStyle: {
    container: {
      borderColor: '#DCE0E7',
      _first: {
        borderTop: 'none',
      },
      _last: {
        borderBottom: 'none',
      },
    },
    button: {
      fontSize: {
        base: '16px',
        desktop: '20px',
      },
      fontWeight: 'semibold',
      borderRadius: '4px',
    },
  },
};
