export default {
  html: {
    scrollBehavior: 'smooth',
  },
  body: {
    overflowX: 'hidden',
    color: 'sapphire.100',
    bg: 'white',
    a: {
      _hover: {
        textDecoration: 'none',
        '.animated-icon-translateX': {
          transform: 'translateX(3px)',
          transition: 'transform 180ms ease-out 0s',
        },
      },
    },
    lineHeight: 'normal',
  },
  '*::placeholder': {
    color: 'rgba(11,37,75,0.5)',
  },
  '*, *::before, &::after': {
    borderColor: 'transparent',
    wordWrap: 'break-word',
  },
  '.grecaptcha-badge': { visibility: 'hidden' },
};
