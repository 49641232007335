export default {
  baseStyle: {
    fontWeight: 'semibold',
  },
  sizes: {
    xxl: {
      fontSize: ['32px', null, '56px'],
      lineHeight: ['42px', null, '64px'],
    },
    xl: {
      fontSize: ['32px', null, '40px'],
      lineHeight: ['42px', null, '50px'],
    },
    lg: {
      fontSize: ['24px', null, '32px'],
      lineHeight: ['34px', null, '42px'],
    },
    md: {
      fontSize: ['20px', null, '24px'],
      lineHeight: ['30px', null, '34px'],
    },
    sm: {
      fontSize: ['16px', null, '20px'],
      lineHeight: ['24px', null, '30px'],
    },
    xs: {
      fontSize: ['10px', null, '14px'],
      lineHeight: ['16px', null, '22px'],
    },
  },
};
