export default {
  sapphire: {
    100: '#0B254B',
    70: 'rgba(11,37,75,0.7)',
    50: 'rgba(11,37,75,0.5)',
    30: 'rgba(11,37,75,0.3)',
    10: 'rgba(11,37,75,0.1)',
  },
  azure: {
    100: '#267FFD',
    50: 'rgba(38, 127, 253, 0.5)',
    25: 'rgba(38, 127, 253, 0.25)',
    10: 'rgba(38, 127, 253, 0.1)',
    8: 'rgba(38, 127, 253, 0.08)',
    4: 'rgba(38, 127, 253, 0.04)',
  },
  gray: {
    70: 'rgba(11, 37, 75, 0.7)',
    10: 'rgba(11, 37, 75, 0.1)',
  },
  green: {
    100: 'rgb(0, 205, 105)',
    12: 'rgba(0, 205, 105, 0.12)',
    5: 'rgba(0, 205, 105, 0.05)',
  },
  orange: {
    100: 'rgb(255, 131, 43)',
    8: 'rgba(255, 131, 43, 0.08)',
    5: 'rgba(255, 131, 43, 0.05)',
  },
  yellow: {
    100: 'rgb(255, 197, 41)',
    5: 'rgba(255, 197, 41, 0.05)',
  },
  white: {
    90: 'rgba(255, 255, 255, 0.9)',
    70: 'rgba(255, 255, 255, 0.7)',
    20: 'rgba(255, 255, 255, 0.2)',
    16: 'rgba(255, 255, 255, 0.16)',
    10: 'rgba(255, 255, 255, 0.1)',
  },
  neutral: {
    200: '#f3f5f8',
    100: '#eef0f3',
    60: 'rgba(243, 245, 248, 0.6)',
  },
  quickbooks: 'rgb(36, 162, 5)',
};
