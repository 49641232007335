import { useEffect } from 'react';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { theme } from 'theme';
import 'focus-visible/dist/focus-visible';
import { AppProps } from 'next/app';
import 'react-modal-video/css/modal-video.min.css';
import { useHighEngagementTracking } from 'lib/hooks/useHighEngagementTracking';
import Script from 'next/script';
import { AnalyticsProvider, useAnalytics } from 'lib/Analytics';

export default function MyApp(props: AppProps) {
  return (
    <ChakraProvider theme={extendTheme(theme)}>
      <TrackingScripts />
      <AnalyticsProvider>
        <Page {...props} />
      </AnalyticsProvider>
    </ChakraProvider>
  );
}

type CustomPageProps = {
  pageData?: { name: string };
};

function Page({ Component, pageProps }: AppProps<CustomPageProps>) {
  const { page } = useAnalytics();
  useEffect(() => {
    page(pageProps.pageData?.name);
  }, [pageProps?.pageData?.name]);

  useHighEngagementTracking();

  return <Component {...pageProps} />;
}

function TrackingScripts() {
  return (
    <>
      <Script src="https://js.hscta.net/cta/current.js" type="text/javascript" strategy="beforeInteractive" />
      {process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY && (
        <Script
          src={`https://www.google.com/recaptcha/enterprise.js?render=${process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}`}
        />
      )}
      {process.env.NEXT_PUBLIC_CLEARBIT_REVEAL_PUBLIC_KEY && (
        <Script
          src={`https://tag.clearbitscripts.com/v1/${process.env.NEXT_PUBLIC_CLEARBIT_REVEAL_PUBLIC_KEY}/tags.js`}
        />
      )}
      <Script id="g2-reveal">{`(function(c, p, d, u, id, i) {
        id = ''; // Optional Custom ID for user in your system
        u = 'https://tracking.g2crowd.com/attribution_tracking/conversions/' + c + '.js?p=' + encodeURI(p) + '&e=' + id;
        i = document.createElement('script');
        i.type = 'application/javascript';
        i.async = true;
        i.src = u;
        d.getElementsByTagName('head')[0].appendChild(i);
      }('1007100', document.location.href, document));`}</Script>
    </>
  );
}
