import { createContext, ReactNode, useContext, useMemo, VFC } from 'react';
import { AnalyticsBrowser } from '@segment/analytics-next';

const AnalyticsContext = createContext<AnalyticsBrowser>(undefined);

export const AnalyticsProvider: VFC<{ children: ReactNode }> = ({ children }) => {
  const writeKey = process.env.NEXT_PUBLIC_ANALYTICS_WRITE_KEY;
  const analytics = useMemo(() => AnalyticsBrowser.load({ writeKey }, { useQueryString: false }), [writeKey]);

  return <AnalyticsContext.Provider value={analytics}>{children}</AnalyticsContext.Provider>;
};

// Create an analytics hook that we can use with other components.
export const useAnalytics = () => {
  const analytics = useContext(AnalyticsContext);

  return {
    identify: (userTraits: Record<string, unknown>) => identify(analytics, userTraits),
    track: (event: string, properties: Record<string, unknown> = {}) => track(analytics, event, properties),
    page: (name: string, properties: Record<string, unknown> = {}) => page(analytics, name, properties),
  };
};

function identify(analytics: AnalyticsBrowser, userTraits: Record<string, unknown>): void {
  if (!analytics) return;

  if (process.env.NODE_ENV !== 'production') {
    console.debug('[Segment] Identify', userTraits);
  }

  void analytics.identify(userTraits);
}

function track(analytics: AnalyticsBrowser, event: string, properties: Record<string, unknown> = {}): void {
  if (!analytics) return;

  if (process.env.NODE_ENV !== 'production') {
    console.debug('[Segment] Track', event, properties);
  }

  void analytics.track(event, properties);
}

function page(analytics: AnalyticsBrowser, name: string, properties: Record<string, unknown> = {}): void {
  if (!analytics) return;

  const enrichedProperties = {
    path: window.location.pathname,
    ...properties,
  };

  if (process.env.NODE_ENV !== 'production') {
    console.debug('[Segment] Page', name, enrichedProperties);
  }

  void analytics.page(name, enrichedProperties);
}
