const subtleColorSchemesIndex = {
  blue: {
    bg: 'rgba(38, 127, 253, 0.08)',
    color: 'azure.100',
  },
  white: {
    bg: 'white',
    color: 'sapphire.100',
  },
};

export default {
  defaultProps: {
    colorScheme: 'blue',
  },
  baseStyle: {
    container: {
      textTransform: 'uppercase',
      letterSpacing: 0.5,
      fontWeight: 600,
      lineHeight: 1,
    },
  },
  sizes: {
    sm: {
      container: {
        borderRadius: 'full',
        fontSize: '10px',
        minHeight: '18px',
        p: '0 6px',
      },
    },
    md: {
      container: {
        borderRadius: 'full',
        fontSize: '12px',
      },
    },
    lg: {
      container: {
        borderRadius: 'full',
      },
    },
  },
  variants: {
    subtle: ({ colorScheme }) => ({
      container: {
        ...subtleColorSchemesIndex[colorScheme],
      },
    }),
  },
};
