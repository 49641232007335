import { useEffect, useState } from 'react';
import BrowserInteractionTime from 'browser-interaction-time';
import { useAnalytics } from 'lib/Analytics';

const ENGAGEMENT_TIME = 45000; // milliseconds
const ENGAGEMENT_SCROLL_RATIO = 0.75; // 75%

export function useHighEngagementTracking() {
  const { track } = useAnalytics();
  const [hasReached75, setHasReached75] = useState<boolean>(false);
  const [hasSpent45s, setHasSpent45s] = useState<boolean>(false);

  useEffect(() => {
    document.addEventListener('scroll', handleScroll);

    const browserInteractionTime = new BrowserInteractionTime({
      idleTimeoutMs: 5000,
      checkCallbacksIntervalMs: 1000,
    });
    browserInteractionTime.addAbsoluteTimeEllapsedCallback({
      timeInMilliseconds: ENGAGEMENT_TIME,
      callback: () => {
        setHasSpent45s(true);
        browserInteractionTime.stopTimer();
        document.removeEventListener('scroll', handleScroll);
      },
      pending: true,
    });
    browserInteractionTime.startTimer();

    return () => {
      document.removeEventListener('scroll', handleScroll);
      browserInteractionTime.destroy();
    };
  }, []);

  useEffect(() => {
    if (hasReached75 && hasSpent45s) {
      track('Page Engagement Detected', {
        scroll_depth: '>75',
        time_spent: '>45',
      });
    }
  }, [hasReached75, hasSpent45s]);

  function handleScroll() {
    const scrollRatio = (window.scrollY + window.innerHeight) / document.body.scrollHeight;
    if (scrollRatio > ENGAGEMENT_SCROLL_RATIO) {
      setHasReached75(true);
    }
  }
}
