const size = {
  xl: {
    fontSize: '16px',
    px: 4,
    h: '56px',
    borderRadius: '4px',
  },
  lg: {
    fontSize: '16px',
    px: 4,
    h: 12,
    borderRadius: '4px',
  },
  md: {
    fontSize: '16px',
    px: 4,
    h: 10,
    borderRadius: '4px',
  },
  sm: {
    fontSize: 'sm',
    px: 3,
    h: 8,
    borderRadius: '4px',
  },
  xs: {
    fontSize: 'xs',
    px: 2,
    h: 6,
    borderRadius: '4px',
  },
};

export default {
  variants: {
    outline: {
      field: {
        bg: 'white',
        borderColor: 'rgba(11, 37, 75, 0.12)',
        boxShadow: '0px 2px 4px -3px rgba(11, 37, 75, 0.12)',
        _hover: {
          borderColor: 'rgba(11, 37, 75, 0.24)',
          boxShadow: '0px 2px 4px -3px rgba(11, 37, 75, 0.12)',
        },
        _focus: {
          borderColor: '#267FFD',
          boxShadow: '0px 0px 0px 3px rgba(38, 127, 253, 0.08), 0px 2px 4px -3px rgba(11, 37, 75, 0.12)',
        },
      },
    },
    filled: {
      field: {
        bg: 'white',
        color: 'sapphire.70',
        border: 'none',
        _hover: {
          bg: 'white',
          boxShadow: '0px 0px 0px 4px rgba(255, 255, 255, 0.32)',
        },
        _focus: {
          boxShadow: '0px 0px 0px 4px rgba(255, 255, 255, 0.32)',
          bg: 'white',
        },
      },
    },
  },
  sizes: {
    xl: {
      field: size.xl,
      addon: size.xl,
    },
    lg: {
      field: size.lg,
      addon: size.lg,
    },
    md: {
      field: size.md,
      addon: size.md,
    },
    sm: {
      field: size.sm,
      addon: size.sm,
    },
    xs: {
      field: size.xs,
      addon: size.xs,
    },
  },
};
