export default {
  defaultProps: {
    variant: 'default',
    size: 'md',
  },
  sizes: {
    md: {
      fontSize: {
        base: '16px',
        desktop: '20px',
      },
      lineHeight: {
        base: '24px',
        desktop: '30px',
      },
    },
    sm: {
      fontSize: {
        base: '14px',
        desktop: '16px',
      },
      lineHeight: {
        base: '20px',
        desktop: '24px',
      },
    },
  },
  variants: {
    unstyled: {},
    default: {
      color: 'sapphire.70',
      whiteSpace: 'pre-line',
    },
    uppercase: {
      fontWeight: 'medium',
      textTransform: 'uppercase',
      letterSpacing: '1px',
    },
  },
};
