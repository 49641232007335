export default {
  default: {
    color: 'sapphire.100',
  },
  sapphire: {
    bg: 'sapphire.100',
    color: 'white',
  },
  'sapphire-shadow': {
    bg: 'sapphire.100',
    color: 'white',
    boxShadow:
      '0px 0px 0px 1px #0B254B, 0px 2px 2px rgba(11, 37, 75, 0.02), 0px 4px 4px rgba(11, 37, 75, 0.02), 0px 14px 14px rgba(11, 37, 75, 0.02), 0px 22px 22px rgba(11, 37, 75, 0.01), 0px 30px 40px -10px rgba(11, 37, 75, 0.04)',
  },
  azure: {
    bg: 'azure.100',
    color: 'white',
  },
  'azure-shadow': {
    bg: 'azure.100',
    color: 'white',
    boxShadow:
      '0px 0px 0px 1px #267FFD, 0px 2px 2px rgba(11, 37, 75, 0.02), 0px 4px 4px rgba(11, 37, 75, 0.02), 0px 14px 14px rgba(11, 37, 75, 0.02), 0px 22px 22px rgba(11, 37, 75, 0.01), 0px 30px 40px -10px rgba(11, 37, 75, 0.04)',
  },
  white: {
    bg: 'white',
    color: 'sapphire.100',
  },
  'white-shadow': {
    bg: 'white',
    color: 'sapphire.100',
    boxShadow: 'xl',
  },
  lightAzure: {
    bg: 'azure.4',
    color: 'sapphire.100',
    p: {
      color: 'sapphire.70',
    },
  },
  'dark-blue': {
    color: 'white',
    bg: '#030b17',
    p: {
      color: 'hsla(0,0%,100%,.7)',
    },
    '.text-accent': {
      color: 'white',
    },
  },
  'matt-blue': {
    color: 'white',
    bg: '#121925',
    p: {
      color: 'hsla(0,0%,100%,.7)',
    },
    '.chakra-accordion__panel': {
      color: 'hsla(0,0%,100%,.7)',
      a: {
        color: 'azure.100',
        _hover: {
          color: 'azure.100',
          opacity: '.7',
        },
      },
    },
    '.chakra-accordion__item': {
      borderColor: 'hsla(0,0%,100%,.12)',
    },
  },
};
