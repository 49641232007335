export default {
  baseStyle: {
    list: {
      boxShadow: 'lg',
      '&:focus:not([data-focus-visible-added])': {
        boxShadow: 'lg',
      },
      p: '12px',
    },
    groupTitle: {
      fontSize: 'xs',
      fontWeight: 'medium',
      textTransform: 'uppercase',
      letterSpacing: '1px',
      color: 'rgba(11, 37, 75, 0.5)',
      mb: '16px',
      mt: '12px',
    },
  },
};
