export default {
  baseStyle: {
    transitionProperty: 'color',
    transitionDuration: '200ms',
    transitionTimingFunction: 'ease-out',
  },
  variants: {
    visible: {
      fontWeight: 500,
      borderBottom: '1px solid',
      pb: '8px',
      transition: '.2s ease-out',
      _hover: {
        borderColor: 'transparent',
        textDecoration: 'none',
      },
    },
    accent: {
      opacity: 0.6,
      transition: 'opacity .2s ease-out',
      _hover: {
        opacity: 1,
        textDecoration: 'none',
      },
    },
    unstyled: {
      _hover: {
        textDecoration: 'none',
      },
    },
    tab: {
      padding: { base: '2px 10px', desktop: '0 8px 8px' },
      lineHeight: '20px',
      color: 'sapphire.70',
      fontSize: '13px',
      fontWeight: 'medium',
      whiteSpace: 'nowrap',
      border: { base: '1px solid', desktop: 'none' },
      borderBottom: { base: '1px solid', desktop: '2px solid' },
      borderColor: { base: 'neutral.100', desktop: 'transparent' },
      borderRadius: { base: '12px', desktop: 0 },
      _hover: {
        color: 'sapphire.100',
        textDecoration: 'none',
      },
      _selected: {
        color: { base: 'white', desktop: 'sapphire.100' },
        borderColor: 'azure.100',
        backgroundColor: { base: 'azure.100', desktop: 'unset' },
      },
    },
  },
};
