import { ButtonProps } from '@chakra-ui/react';

const solidColorSchemes = {
  azure: {
    bg: 'azure.100',
    color: 'white',
    _hover: {
      bg: '#1f69d1',
    },
  },
  g2: {
    bg: '#ff492c',
    boxShadow: '0 2px 4px -2px rgb(11 37 75 / 12%), 0 0 0 1px #ff492c',
    color: 'white',
    _hover: {
      bg: '#df1f00',
    },
  },
  lightAzure: {
    bg: 'azure.8',
    color: 'azure.100',
    _hover: {
      bg: 'azure.100',
      color: 'white',
    },
  },
  gray: {
    bg: 'neutral.100',
    color: 'sapphire.70',
    _hover: {
      bg: 'neutral.400',
      color: 'sapphire.100',
    },
    _focus: {
      bg: '#DCE0E7',
      color: 'sapphire.100',
    },
  },
  whiteAlpha: {
    bg: 'rgba(255, 255, 255, 0.16)',
    _hover: {
      bg: 'rgba(255, 255, 255, 0.24)',
    },
  },
  whiteSapphire: {
    bg: 'white',
    color: 'azure.100',
    _hover: {
      color: 'white',
      bg: 'sapphire.100',
    },
  },
  whiteAzure: {
    bg: 'white',
    color: 'sapphire.100',
    _hover: {
      color: 'white',
      bg: 'azure.100',
    },
  },
  lightAzureAzure: {
    bg: 'azure.8',
    color: 'azure.100',
    _hover: {
      color: 'white',
      bg: 'azure.100',
    },
  },
  lightWhiteAzure: {
    bg: 'white.16',
    boxShadow: '0 0 0 1px rgba(255, 255, 255, 0.16)',
    color: 'white',
    _hover: {
      bg: 'white',
      boxShadow: '0 0 0 1px white',
      color: 'azure.100',
    },
  },
};

const linkColorSchemes = {
  sapphire: {
    color: 'sapphire.100',
    _hover: {
      textDecoration: 'none',
    },
  },
  azure: {
    color: 'azure.100',
    _hover: {
      textDecoration: 'none',
    },
  },
  gray: {
    color: 'rgba(11, 37, 75, 0.7)',
    _hover: {
      textDecoration: 'none',
      color: 'rgba(11, 37, 75, 1)',
    },
  },
  sapphireAzure: {
    color: 'sapphire.100',
    transition: 'color 180ms ease-out',
    _hover: {
      textDecoration: 'none',
      color: 'azure.100',
    },
  },
  azureSapphire: {
    color: 'azure.100',
    transition: 'color 180ms ease-out',
    _hover: {
      textDecoration: 'none',
      color: 'sapphire.100',
    },
  },
  whiteAzure: {
    color: 'white',
    _hover: {
      color: 'azure.100',
    },
  },
};

const ghostColorSchemes = {
  azure: {
    color: 'azure.100',
    _hover: {
      bg: '#eef5ff',
    },
  },
};

export default {
  baseStyle: {
    borderRadius: '4px',
    _hover: {
      '.animated-icon-translateX': {
        transform: 'translateX(3px)',
        transition: 'transform 180ms ease-out 0s',
      },
    },
  },
  defaultProps: {
    colorScheme: 'azure',
  },
  sizes: {
    sm: {
      height: '32px',
      px: '12px',
      fontSize: '14px',
      fontWeight: 'semibold',
    },
    md: {
      height: '40px',
      px: '16px',
      fontSize: '16px',
      fontWeight: 'semibold',
    },
    lg: {
      height: '48px',
      px: '24px',
      fontSize: '20px',
      fontWeight: 'semibold',
    },
    xl: {
      height: '56px',
      px: '24px',
      fontSize: '16px',
      fontWeight: 'semibold',
    },
  },
  variants: {
    solid: ({ colorScheme }: ButtonProps) => ({
      ...solidColorSchemes[colorScheme],
    }),
    link: ({ colorScheme }: ButtonProps) => ({
      ...linkColorSchemes[colorScheme],
      fontWeight: 'medium',
      minW: 'unset',
    }),
    ghost: ({ colorScheme }: ButtonProps) => ({
      ...ghostColorSchemes[colorScheme],
    }),
  },
};
