export default {
  baseStyle: {
    bg: 'sapphire.100',
    color: 'white',
    padding: '12px',
    borderRadius: '4px',
    fontSize: '12px',
    maxW: '250px',
    boxShadow: 'none',
  },
};
