export default {
  variants: {
    filled: {
      field: {
        bg: 'neutral.100',
        color: 'sapphire.70',
        _hover: {
          bg: 'neutral.400',
          color: 'sapphire.100',
        },
        _focus: {
          bg: '#DCE0E7',
          color: 'sapphire.100',
        },
      },
    },
  },
};
