export default {
  defaultProps: {
    size: 'default',
  },
  baseStyle: {
    px: '40px',
    maxWidth: '1280px',
  },
  sizes: {
    default: {
      py: {
        base: '70px',
        desktop: '104px',
      },
    },
    py50: {
      py: {
        base: '25px',
        desktop: '52px',
      },
    },
    pt50: {
      pt: {
        base: '25px',
        desktop: '52px',
      },
      pb: {
        base: '50px',
        desktop: '104px',
      },
    },
    pb50: {
      pt: {
        base: '50px',
        desktop: '104px',
      },
      pb: {
        base: '25px',
        desktop: '52px',
      },
    },
    'pt50.pb0': {
      pt: {
        base: '25px',
        desktop: '52px',
      },
    },
    'pt0.pb50': {
      pb: {
        base: '25px',
        desktop: '52px',
      },
    },
    pt0: {
      pb: {
        base: '50px',
        desktop: '104px',
      },
    },
    py0: {
      py: 0,
    },
    pb0: {
      pt: {
        base: '50px',
        desktop: '104px',
      },
    },
    ptHeader: {
      pt: {
        base: 'calc(50px + 70px)',
        desktop: 'calc(104px + 90px)',
      },
      pb: {
        base: '50px',
        desktop: '104px',
      },
    },
    ptHeader50: {
      pt: {
        base: 'calc(25px + 70px)',
        desktop: 'calc(52px + 90px)',
      },
      pb: {
        base: '50px',
        desktop: '104px',
      },
    },
    ptHeader0: {
      pt: {
        base: '70px',
        desktop: '90px',
      },
      pb: {
        base: '50px',
        desktop: '104px',
      },
    },
    'ptHeader0.pb0': {
      pt: {
        base: '70px',
        desktop: '90px',
      },
    },
    'ptHeader.pb0': {
      pt: {
        base: 'calc(50px + 70px)',
        desktop: 'calc(104px + 90px)',
      },
    },
    'ptHeader50.pb0': {
      pt: {
        base: 'calc(25px + 70px)',
        desktop: 'calc(52px + 90px)',
      },
    },
    'ptHeader50.pb50': {
      pt: {
        base: 'calc(25px + 70px)',
        desktop: 'calc(52px + 90px)',
      },
      pb: {
        base: '25px',
        desktop: '52px',
      },
    },
  },
};
